/*-------------------------
  global-nav
-------------------------*/
const checkbox = document.getElementById('nav-check');
const targets = document.getElementsByClassName('global-nav-list__link');
for (let i = 0; i < targets.length; i++) {
  targets[i].addEventListener(
    'click',
    () => {
      checkbox.checked = false;
    },
    false
  );
}

const unCheck = () => {
  checkbox.checked = false;
  document.body.classList.remove('navopen');
};

const overlay = document.getElementById('nav-overlay');
overlay.onclick = unCheck;

//チェックを変更すると発生するイベントを設置
checkbox.addEventListener('change', function () {
  if (checkbox.checked) {
    document.body.classList.add('navopen');
  } else {
    document.body.classList.remove('navopen');
  }
});
